import './App.css';
import logo from './assets/logo.png';

function App() {
  return (
    <div id="App" className=''>
      <div className='title_div pt-2 flex flex-nowrap flex-row items-center px-2'>
        <img className='w-24' src={logo} />
        <h1 className='text-7xl font-black'>Sansfinbags</h1>
      </div>


      <div class="grid grid-cols-2 gap-4 mx-14">
        {/* <!-- Left half --> */}
        <div class="col-span-1">
          {/* <!-- Content for the left half goes here --> */}
          <div className='left_half_div py-2 '>
            <div id='left_hook'>
              <p className='italic text-lg pt-2'>
                  Unleash your potential with Sansfinbags. Your premier destination for wholesale tote bags!
              </p>
            </div>

            <div className='hook_helper mt-8'>
              <p className='text-xl w-70 font-bold'>
                Are You On The Hunt For A Reliable, High-Quality Tote Bag Supplier? Here's Why Sansfinbags Is The Answer To Your Quest
              </p>
            </div>

            <div className='answer_to_quest w-3/4'>
              <p className='pt-4 text-justify'>
                Our promise is superior quality and durability, a result of impeccable craftsmanship. Our tote bags are built to last, just like your business!
              </p>

              <p className='pt-4 text-justify'>
                Craft Your Signature: Amplify your brand's individuality. Our expert printing and embroidery options provide a canvas to let your brand narrative shine
              </p>

              <p className='pt-4 text-justify'>
                Pledge for the Plan: Showcase your brand's dedication to sustainability with Sansfinbags
              </p>

              <p className='pt-4 text-justify'>
                Prosper with Us! Looking for a way to revolutionize your business? Dive into our exclusive wholesale strategy tailor-made for your success
              </p>
            </div>

          <div className='why_choose_us pt-8 w-3/4'>
            <div className='why_choose_us_heading'>
              <p className=' font-bold text-2xl ' >
                Why Choose Us?
              </p>
            </div>
            <div className='why_choose_us_answer '>
              <p className='pt-4 text-justify'>
                Elegance in Simplicity: Our secret to style? It's all about simplicity.  We've turned sophistication into a wearable art form
              </p>

              <p className='pt-4 text-justify'>
                Durability that Speaks:  Our bags stand tall against time and competition - a testament to our intricate craftsmanship
              </p>

              <p className='pt-4 text-justify'>
                Elevate Your Business: Let Sansfinbags be the backbone of your brand's narrative
              </p>
            </div>
          </div>

        </div>
        </div>

        {/* <!-- Right half --> */}
        <div class="col-span-1">
          {/* <!-- Content for the right half goes here --> */}
          <div className='right_half_div py-2 '>
            <div className='lets_build_together_main w-3/5 ml-auto'>
                <div className='lets_build_together_heading pb-2'>
                <p className='text-2xl font-bold  pb-2'> Let's Build Together</p>
                </div>

                <div className='lets_build_together_answer'>
                  <p className='pt-2'>
                    Enduring Partnerships: Are you tired of fleeting suppliers? Experience the consistency of a lasting partnership with Sansfinbags
                  </p>

                  <p className='pt-4 text-justify'>
                    Brand Amplification: Envision your brand's logo showcased on a tote bag, narrating its distinctive tale. That's the power of our customization options
                  </p>

                  <p className='pt-4 text-justify'>
                    Stand for green: Bypassing sustainability can cost your brand's reputation. Stand tall with our eco-friendly tote bags and join the green revolution
                  </p>

                  <p className='pt-4 text-justify font-bold'>
                  Trust the Best: We've got your back. Offering style, durability, personalization, and sustainability, Sansfinbags is the ultimate tote bag supplier!
                </p>
              </div>
            </div>

           <div className='brevo-form ml-auto w-3/5 mr-4'>
             <iframe
                width="540"
                height="624"
                src="https://07598e66.sibforms.com/serve/MUIFACQPLJR4p0M7tBxq13DktyGFnnONHprgwOcAnNizWuOd_IqnJcC2XB-ZJ6OWlMF_UojCB4Q6SZDgloDzY7E-21dixhUwtfejex4vgwgA8rmPp1lirF6gxC1MMlovhiFovXOBSyCvxg7Ru9NNnwEV2fEW_70gmEhXHKDczjFvfdddQdr9Sca6o1-6nLZD8kZNZOH9J_DkzpIs"
                frameborder="0"
                scrolling="auto"
                allowfullscreen >

              </iframe>
           </div>

          </div>
        </div>
      </div>

    <footer class="fixed bottom-0 left-0 w-full bg-neutral-950 text-white py-2">
      <div class="container mx-auto text-center">
        <p>&copy; 2023 Sansfin bags. All rights reserved.</p>
      </div>
    </footer>

    </div>
  );
}

export default App;
